import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Grid from "../../../../../components/grid"
import Image from "../../../../../components/image"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const data = useStaticQuery(graphql`
    query KurseZahlenspieleBloedigkeitQuery {
      nachtgesaenge05: file(
        relativePath: { eq: "kurse/nachtgesaenge-05.png" }
      ) {
        ...largeImage
      }
      nachtgesaenge06: file(
        relativePath: { eq: "kurse/nachtgesaenge-06.png" }
      ) {
        ...largeImage
      }
      poster: file(relativePath: { eq: "kurse/poster-bachmann-oden.png" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/zahlenspiele/haelfte-des-lebens" />
      }
    >
      <Seo title="Versteckten Zahlenspielen nachgehen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versteckten Zahlenspielen nachgehen
            </Heading>
          </Stack>
          <Paragraph>
            Bei dem fünften Gedicht, ›Blödigkeit‹, handelt es sich um eine
            Asklepiadeische Ode. Im Entwurf sollte das Gedicht zunächst
            ›Dichtermuth‹ heißen.
          </Paragraph>
          <Grid columns={[1, 2]} space={1} alignY="center">
            <Image
              alt=""
              image={data.nachtgesaenge05.childImageSharp.gatsbyImageData}
            />
            <Image
              alt=""
              image={data.nachtgesaenge06.childImageSharp.gatsbyImageData}
            />
          </Grid>
          <Paragraph>
            Unsere Ausstellungsvermittlerin Andrea Bachmann spricht über
            Hölderlins Adaption der griechischen Oden:
          </Paragraph>
          <VideoPlayer src="kurse/bachmann-oden" poster={data.poster} />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
